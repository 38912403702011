import React from "react";

const Home = React.lazy(() => import("features/home/Home"));
const MyCollection = React.lazy(() => import("features/MyCollection"));
const NotFound = React.lazy(() => import("components/exception/NotFound"));
const Search = React.lazy(() => import("features/search/Search"));
const Privacy = React.lazy(() => import("features/privacy/Privacy"));
const Accept = React.lazy(() => import("features/accept/Accept"));
const Video = React.lazy(() => import("features/sharing/Video"));
const OrderList = React.lazy(() => import("features/orderList/OrderList"));

const Logistics = React.lazy(() => import("features/logistics/Logistics"));

const Demo = React.lazy(() => import("features/demo/Demo"));

const NewsDetails = React.lazy(() => import("features/newsDetails/NewsDetails"));
const AddressManagement = React.lazy(() => import("features/AddressManagement"));
const Address = React.lazy(() => import("features/AddressManagement/components/Address"));
const HalfAddress = React.lazy(() => import("features/AddressManagement/components/HalfAddress"));
const VotingDetails = React.lazy(() => import("features/VotingDetails"));
const SignUp = React.lazy(() => import("features/VotingDetails/SignUp"));
const Bulletin = React.lazy(() => import("features/Bulletin"));
const Instructions = React.lazy(() => import("features/VotingDetails/Instructions"));
const CandidateDetails = React.lazy(() => import("features/VotingDetails/CandidateDetails"));
const VoteHome = React.lazy(() => import("features/votehome/VoteHome"));
const DemocraticCommunity = React.lazy(() => import("features/DemocraticCommunity"));
const ConstitutionConvention = React.lazy(() => import("features/DemocraticCommunity/ConstitutionConvention"));
const ConstitutionConvention_Details = React.lazy(() =>
  import("features/DemocraticCommunity/ConstitutionConvention/Details")
);
const ClaimSubmission = React.lazy(() => import("features/DemocraticCommunity/ClaimSubmission"));
const ClaimSubmissionMyForm = React.lazy(() => import("features/DemocraticCommunity/ClaimSubmission/MyForm"));
const ResultFeedback = React.lazy(() => import("features/DemocraticCommunity/ResultFeedback"));
const Proposal = React.lazy(() => import("features/DemocraticCommunity/Proposal"));
const Motion = React.lazy(() => import("features/DemocraticCommunity/Motion"));
const Agenda = React.lazy(() => import("features/DemocraticCommunity/Agenda"));
const NegotiationRules = React.lazy(() => import("features/DemocraticCommunity/NegotiationRules"));
const BulletinAlter = React.lazy(() => import("features/Bulletin/BulletinAlter"));
// 事件
const Supervision = React.lazy(() => import("features/incident/supervision/Supervision"));
const RecordList = React.lazy(() => import("features/incident/supervision/recordList/RecordList"));
const RecordAlter = React.lazy(() => import("features/incident/supervision/recordList/RecordAlter"));

const routes = [
  { path: "/", index: true, element: <Home />, TabBar: true },
  { path: "/news/:id", element: <NewsDetails /> },
  { path: "/demo", element: <Demo /> },
  { path: "/votehome", element: <VoteHome /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/orderlist", element: <OrderList /> },
  { path: "/supervision", element: <Supervision /> },
  { path: "/recordList", element: <RecordList /> },

  { path: "/accept", element: <Accept /> },
  { path: "/video/:id", element: <Video /> },
  { path: "/logistics", element: <Logistics /> },
  { path: "/search/:name", element: <Search /> },
  { path: "/mycollection", index: true, element: <MyCollection />, TabBar: false },
  { path: "/AddressManagement", index: true, element: <AddressManagement />, TabBar: false },
  { path: "/Address", index: true, element: <Address />, TabBar: false },
  { path: "/HalfAddress", index: true, element: <HalfAddress />, TabBar: false },
  { path: "/votingdetails", index: true, element: <VotingDetails />, TabBar: false },
  { path: "/votingdetails/signup", index: true, element: <SignUp />, TabBar: false },
  { path: "/bulletin/list", index: true, element: <Bulletin />, TabBar: false },
  { path: "/bulletin/:id", index: true, element: <BulletinAlter />, TabBar: false },
  { path: "/votingdetails/instructions", index: true, element: <Instructions />, TabBar: false },
  { path: "/votingdetails/candidatedetails", index: true, element: <CandidateDetails />, TabBar: false },
  { path: "/democraticcommunity", index: true, element: <DemocraticCommunity />, TabBar: false },
  {
    path: "/democraticcommunity/constitutionconvention",
    index: true,
    element: <ConstitutionConvention />,
    TabBar: false,
  },
  {
    path: "/democraticcommunity/constitutionconvention/details",
    index: true,
    element: <ConstitutionConvention_Details />,
    TabBar: false,
  },
  { path: "/democraticcommunity/claimSubmission", index: true, element: <ClaimSubmission />, TabBar: false },
  { path: "/democraticcommunity/claimSubmission/form", index: true, element: <ClaimSubmissionMyForm />, TabBar: false },
  { path: "/democraticcommunity/resultFeedback", index: true, element: <ResultFeedback />, TabBar: false },
  { path: "/democraticcommunity/proposal", index: true, element: <Proposal />, TabBar: false },
  { path: "/democraticcommunity/motion", index: true, element: <Motion />, TabBar: false },
  { path: "/democraticcommunity/agenda", index: true, element: <Agenda />, TabBar: false },
  { path: "/democraticcommunity/negotiationRules", index: true, element: <NegotiationRules />, TabBar: false },
  { path: "*", element: <NotFound /> },
];

export default routes;

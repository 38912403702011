const baseUrl = "rest/";
const serviceName = "client_HomePageService";
export const types = ["Like"];

// eslint-disable-next-line import/no-anonymous-default-export
export default (build) => ({
  getsearch: build.mutation({
    query: (data) => ({
      url: `/api/search/indexTag`,
      method: "post",
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 新闻详情
  getNewsdetails: build.query({
    query: (id) => ({
      url: `/api/index/detail`,
      method: "get",
      params: { ...id },
    }),
    transformResponse: (response) => {
      return response.data;
    },
    providesTags: () => [{ type: "Like", id: "LIST" }],
  }),

  // 新闻详情点赞接口
  getlike: build.mutation({
    query: (params) => ({
      url: `/api/index/like`,
      method: "get",
      params,
    }),
    invalidatesTags: () => [{ type: "Likse", id: "LIST" }],
  }),

  // 新闻详情收藏接口
  getcollect: build.mutation({
    query: (params) => ({
      url: `/api/index/collect`,
      method: "get",
      params,
    }),
    invalidatesTags: () => [{ type: "Lsike", id: "LIST" }],
  }),
});

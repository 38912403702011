import React from "react";
import { SpinLoading } from "antd-mobile";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  suspenseFallback: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "rgba(0, 0, 0, 0.5)", // 这将创建一个半透明的黑色背景
  },
});

const SuspenseFallback = () => {
  const classes = useStyles();
  return (
    <div className={classes.suspenseFallback}>
      <SpinLoading color="primary" style={{ "--size": "48px" }} />
    </div>
  );
};

export default SuspenseFallback;

export default (build) => ({
  // 章程详情
  regulationGetById: build.mutation({
    query: (params) => ({
      url: `/api/regulation/getById`,
      method: "get",
      params,
    }),
  }),
  // 章程列表
  regulationList: build.mutation({
    query: (data) => ({
      url: `/api/regulation/getAll`,
      method: "post",
      data,
    }),
  }),
  //民主提案列表
  proposalList: build.mutation({
    query: (data) => ({
      url: `/api/region/getAll`,
      method: "post",
      data,
    }),
  }),
  //民主决策列表
  consultList: build.mutation({
    query: (data) => ({
      url: `/api/consult/getAll`,
      method: "post",
      data,
    }),
  }),
  // 提案提交
  proposalSubmit: build.mutation({
    query: (data) => ({
      url: `/api/proposal/submit`,
      method: "post",
      data,
    }),
  }),
  // 协商提交
  consultSubmit: build.mutation({
    query: (data) => ({
      url: `/api/consult/submit`,
      method: "post",
      data,
    }),
  }),
  // 决策提交
  decisionSubmit: build.mutation({
    query: (data) => ({
      url: `/api/decision/submit`,
      method: "post",
      data,
    }),
  }),
  // 结果反馈
  getResultList: build.mutation({
    query: (data) => ({
      url: `/api/region/getResultList`,
      method: "post",
      data,
    }),
  }),
  //投票公告列表
  noticeInfoList: build.mutation({
    query: (data) => ({
      url: `/api/noticeInfo/list`,
      method: "post",
      data,
    }),
  }),
  //提案详情
  proposalGetById: build.mutation({
    query: (params) => ({
      url: `/api/proposal/getById`,
      method: "get",
      params,
    }),
  }),
  //协商详情
  consultGetById: build.mutation({
    query: (params) => ({
      url: `/api/consult/getById`,
      method: "get",
      params,
    }),
  }),
  ruleById: build.mutation({
    query: (params) => ({
      url: `/api/rule/getById`,
      method: "get",
      params,
    }),
  }),
  //决策详情
  decisionGetById: build.mutation({
    query: (params) => ({
      url: `/api/decision/getById`,
      method: "get",
      params,
    }),
  }),
});

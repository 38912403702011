import React, { useEffect, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";

import SuspenseFallback from "components/SuspenseFallback";
import HomeLayout from "components/layout/HomeLayout";
import { authActions } from "store/authSlice";
import useBlurOnScroll from "hooks/useBlurOnScroll";

import routes from "./routes";

import Vconsole from "vconsole";
// console.log(VConsole);

const MyComponent = () => {
  // useBlurOnScroll();
  const dispatch = useDispatch();
  if (process.env.REACT_APP_ENV !== "prd") {
    const VConsole = new Vconsole();
  }
  const app = useRoutes(routes);

  function parseUserAgent(ua) {
    if (ua) {
      const parts = ua.split("/");
      const result = {
        app: parts[0],
        platform: parts[1],
        device: parts[2],
        deviceId: parts[3],
        appVersion: parts[4],
        apiVersion: parts[5],
        osVersion: parts[6],
        bundleId: parts[7],
        channel: parts[8],
        browserInfo: parts.slice(9).join("/"),
      };
      return result;
    }

    return ua;
  }

  const [configList, setConfigList] = React.useState();
  // callback 是一个函数，它接受一个 bridge 参数。这个 bridge 参数是 WebViewJavascriptBridge 对象，它提供了与 iOS 交互的接口。
  const callback = (bridge) => {
    console.log("打印 bridge 对象，用于调试", bridge); // 打印 bridge 对象，用于调试。
    // bridge.registerHandler 方法用于注册一个 JavaScript 函数，iOS 可以通过 bridge 调用这个函数。
    bridge.registerHandler(
      "FormInformation", // 这是 iOS 调用这个函数的名称。
      // 这是 iOS 调用时会执行的 JavaScript 函数。它接受两个参数：data 是 iOS 传递的数据，responseCallback 是一个函数，JavaScript 可以通过它向 iOS 返回数据。
      function (data, responseCallback) {
        console.log("OC调用JS方法成功", data); // 打印从 iOS 传递过来的数据，用于调试。
        const responseData = { formList: "回调值!" }; // 创建要返回给 iOS 的数据。
        // console.log("OC调用JS的返回值", responseData); // 打印要返回给 iOS 的数据，用于调试。
        responseCallback(responseData); // 通过 responseCallback 函数将数据返回给 iOS。
      }
    );
  };

  // setupWebViewJavascriptBridge 是一个函数，它接受一个 callback 参数，并尝试设置 WebViewJavascriptBridge。
  const setupWebViewJavascriptBridge = (callback) => {
    // 如果 WebViewJavascriptBridge 已经存在，就直接使用它并调用 callback 函数。
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge);
    }

    // 如果 WVJBCallbacks 数组已经存在，就将 callback 函数添加到这个数组。当 WebViewJavascriptBridge 准备好后，数组中的所有函数都会被调用。
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }

    // 如果 WebViewJavascriptBridge 和 WVJBCallbacks 都不存在，就创建 WVJBCallbacks 数组并将 callback 函数添加到其中。
    window.WVJBCallbacks = [callback];

    // 创建一个隐藏的 iframe，通过设置它的 src 为 'https://__bridge_loaded__' 来触发 WebViewJavascriptBridge 的加载。
    let WVJBIframe = document.createElement("iframe");
    WVJBIframe.style.display = "none";
    WVJBIframe.src = "https://__bridge_loaded__";
    document.documentElement.appendChild(WVJBIframe);

    // 在下一个事件循环中，删除刚刚创建的 iframe。这是为了清理 DOM，避免 iframe 对性能产生影响。
    setTimeout(function () {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
  };

  useEffect(() => {
    if (window.WebViewJavascriptBridge) {
      window.WebViewJavascriptBridge.callHandler("login", { value: "11" }, (res) => {
        // console.log(res);
      });
    } else {
      // console.log("WebViewJavascriptBridge 不存在");
      // setLoading(false);
    }
  }, []);

  useEffect(() => {
    setupWebViewJavascriptBridge(callback);
    setConfigList(navigator.userAgent);
    dispatch(authActions.setToken(navigator.userAgent));
  }, []);

  // const { response, loading } = getLogin({ key: "value" });
  // console.log(response, loading, "response");

  return (
    <div>
      {/* <h1>My React Component</h1>
      <button onClick={handleClick}>点击调用iOS方法</button>
      <a href="haike://aifuture.com/login">访问 haike://aifuture.com/login!</a> */}
      {/* <HomeLayout>
        <Suspense fallback={<SuspenseFallback />}>{app}</Suspense>
      </HomeLayout> */}
      <Suspense>{app}</Suspense>
    </div>
  );
};

export default MyComponent;

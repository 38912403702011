import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import api from "services/api";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 处理非序列化数据校验
    }).concat(api.middleware),
});

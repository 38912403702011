const baseUrl = "rest/";
const serviceName = "client_HomePageService";

export default (build) => ({
  gethome: build.query({
    query: () => ({
      url: `/v1/edu/index/index`,
      method: "get",
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});

export default (build) => ({
    myListByArticleVideo: build.mutation({
        query: (data) => ({
            url: `/api/home/myListByArticleVideo`,
            method: "post",
            data
        }),
    }),
    addressList: build.mutation({
        query: (data) => ({
            url: `/api/address/list`,
            method: "post",
            data
        }),
    }),
    addressCreate: build.mutation({
        query: (data) => ({
            url: `/api/address/create`,
            method: "post",
            data
        }),
    }),
    addressUpdate: build.mutation({
        query: (data) => ({
            url: `/api/address/update`,
            method: "post",
            data
        }),
    }),
    addressDelete: build.mutation({
        query: (data) => ({
            url: ` /api/address/delete`,
            method: "post",
            data
        }),
    }),
   
});

import { createApi } from "@reduxjs/toolkit/query/react";

import home from "./home";
import mine from "./mine";
import logistics from "./logistics";
import orderlist from "./orderlist";
import votingdetails from "./votingdetails";
import voteHome, { types as messType } from "./voteHome";
import democraticCommunity from "./democraticCommunity";
import incident from "./incident";

import search, { types as newsLike } from "./search";

import axiosBaseQuery from "./axiosBaseQuery";

const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseURL: "",
    headers: {
      // hkinfo: localStorage.getItem("token") || "",
      channel: "h5",
    },
  }),
  tagTypes: [...newsLike, ...messType],
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: (token) => ({
        url: "rest/userInfo",
        method: "GET",
        headers: { authorization: `Bearer` },
      }),
    }),
    ...home(build),
    ...mine(build),
    ...search(build),
    ...logistics(build),
    ...orderlist(build),
    ...votingdetails(build),
    ...voteHome(build),
    ...incident(build),
    ...democraticCommunity(build)
  }),
});

export default api;

const baseUrl = "rest/";
const serviceName = "client_HomePageService";

// eslint-disable-next-line import/no-anonymous-default-export
export default (build) => ({
  orderList: build.mutation({
    query: (data) => ({
      url: `/api/order/orderList`,
      method: "post",
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),

  orderStatsNum: build.query({
    query: () => ({
      url: `/api/order/orderStatusCountNum`,
      method: "get",
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});

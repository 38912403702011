import axios from "axios";

const axiosBaseQuery =
  ({ baseURL, headers } = { baseURL: "" }) =>
  async (axiosOptions, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;
      const { header = {}, ...restOptions } = axiosOptions || {};
      headers.hkinfo = `${token}`
      axiosOptions.headers = {
        ...axiosOptions.headers,
        ...header,
        ...headers,
      };
      const request = {
        baseURL, // 设置 baseURL
        withCredentials: false,
        ...axiosOptions,
      };
      if (axiosOptions?.signal) {
        request.signal = axiosOptions?.signal;
      }
      const response = await axios(request);
      if (response.data.code === "20004") {
        return console.log("token 失效");
      }
      return { data: response?.data };
    } catch (error) {
      // window.location.href = "/abnormal?t=" + new Date().getTime();
      // console.log("error");
      return { error };
    }
  };

export default axiosBaseQuery;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
  authorized: false,
  token: "",
  user: null,
  isNewUser: false,
  showOnboardingModal: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      // 找到最后一个分号的位置
      const lastSemicolonIndex = action.payload.lastIndexOf(";");

      // 找到最后一个右括号的位置
      const lastParenthesisIndex = action.payload.lastIndexOf(")");
      const token = action.payload.substring(lastSemicolonIndex + 1, lastParenthesisIndex);
      state.token = token;
      localStorage.setItem("token", token);
    },
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice.reducer;

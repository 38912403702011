import React from "react";
import ReactDOM from "react-dom/client";
import App from "features/App";
import "theme/app.css";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import "amfe-flexible";
import { AliveScope } from "react-activation";

import { store } from "store/rootStore";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HashRouter>
      <AliveScope>
        <App />
      </AliveScope>
    </HashRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const baseUrl = "rest/";
const serviceName = "client_HomePageService";

// eslint-disable-next-line import/no-anonymous-default-export
export default (build) => ({
  logicsInfo: build.query({
    query: ({ orderId }) => ({
      url: `/api/logics/info`,
      method: "get",
      params: { orderId: orderId },
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});

export default (build) => ({
  getCode: build.mutation({
    query: (params) => ({
      url: `/api/app/getCode`,
      method: "get",
      params,
    }),
  }),
  voteSignSubmit: build.mutation({
    query: (data) => ({
      url: `/api/vote/sign/submit`,
      method: "post",
      data,
    }),
  }),
  voteSignList: build.mutation({
    query: (data) => ({
      url: `/api/vote/sign/list`,
      method: "post",
      data,
    }),
  }),
  voteInfoGetById: build.mutation({
    query: (data) => ({
      url: `/api/vote/info/getById`,
      method: "post",
      data,
    }),
  }),
  voteInfoSubmit: build.mutation({
    query: (data) => ({
      url: `/api/vote/info/submit`,
      method: "post",
      data,
    }),
  }),
  VotInstructions: build.mutation({
    query: (params) => ({
      url: `/api/vote/info/VotInstructions`,
      method: "get",
      params,
    }),
  }),
  chooseInfoSearchByTitle: build.mutation({
    query: (data) => ({
      url: `/api/chooseInfo/searchByTitle`,
      method: "post",
      data,
    }),
  }),
  
});



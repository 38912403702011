const baseUrl = "rest/";
const serviceName = "client_HomePageService";
export const types = ["reviewList"];

// eslint-disable-next-line import/no-anonymous-default-export
export default (build) => ({
  getvoteList: build.mutation({
    query: (data) => ({
      url: `/api/vote/info/getAll`,
      method: "POST",
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  getvoteimgList: build.query({
    query: (data) => ({
      url: `/api/vote/noticeInfo/getAll`,
      method: "POST",
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  noticeList: build.mutation({
    query: (data) => ({
      url: `/api/noticeInfo/list`,
      method: "post",
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 公告详情
  BulletinAlter: build.query({
    query: (data) => ({
      url: `/api/vote/noticeInfo/getById`,
      method: "POST",
      data: {
        phone: data.phone,
        voteNoticeId: data.voteInfoId,
      },
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 评论列表
  reviewList: build.mutation({
    query: (data) => ({
      url: `/api/vote/comment/commentList`,
      method: "POST",
      data,
    }),
    providesTags: () => [{ type: "reviewList", id: "LIST" }],
  }),
  // 发布评论
  reviewAdd: build.mutation({
    query: (data) => ({
      url: `/api/vote/comment/createComment`,
      method: "POST",
      data,
    }),
    invalidatesTags: () => [{ type: "reviewList", id: "LIST" }],
  }),

  // 图片上传
  ImgUpdate: build.mutation({
    query: (data) => ({
      url: `/api/file/upload`,
      method: "POST",
      data,
    }),
  }),
});

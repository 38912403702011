const baseUrl = "rest/";
const serviceName = "client_HomePageService";

// eslint-disable-next-line import/no-anonymous-default-export
export default (build) => ({
  // 区域列表
  areaList: build.query({
    query: (data) => ({
      url: `/api/vote/sign/addressList`,
      method: "POST",
      data,
    }),
    transformResponse: (response) =>
      response.errorData.addressList.map((item) => ({ label: item?.addressName, value: item?.addressId })),
  }),
  // 监督平台提交
  supervisionSubmit: build.mutation({
    query: (data) => ({
      url: `/api/event/submit`,
      method: "POST",
      data,
    }),
  }),
});
